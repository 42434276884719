<template>
  <main class="Drivers">
    <TheSubHeader
      icon="driver"
      title="Motoristas"
      page-title-tag="Drivers Export"
      type="filter"
      :actions="['see', 'addnew']"
      organizer-gap="1rem"
      organizer-columns="240px 2fr 1.5fr 2fr 1fr 135px"
    >
      <template #filter>
        <!-- Period -->
        <BaseDatePicker
          v-model="formattedDateRange"
          label="Período de criação do registro"
          @input="resetSearch()"
        />
        <!-- Stores -->
        <BaseTreeSelect
          ref="lojista"
          v-model="filter.store_id"
          name="Lojista"
          label="Lojista"
          placeholder="Selecione"
          multiple
          :select-options="null"
          :fetch-options="'stores/fetchStoresAsync'"
          @input="resetSearch()"
        />
        <!-- Transporters -->
        <BaseTreeSelect
          v-model="filter.transporter_id"
          name="Transportadora"
          label="Transportadora"
          placeholder="Selecione"
          multiple
          :select-options="transporters"
          @input="resetSearch()"
        />
        <!-- Driver -->
        <BaseTreeSelect
          v-model="filter.id"
          name="Motorista"
          label="Motorista"
          placeholder="Pesquisar"
          multiple
          suggestions="searchDrivers"
          @input="resetSearch()"
        />
        <!-- Status -->
        <BaseTreeSelect
          v-model="filter.status_id"
          name="Status"
          label="Status"
          placeholder="Selecione"
          multiple
          :select-options="countOpt"
          @input="resetSearch()"
        />
        <!-- Export -->
        <AsyncExportButton
          url="reports-drivers"
          name="Drivers"
          name-report="Drivers"
          color="primary-base"
          text-color="white"
          filled
          icon="download"
          label="Exportar"
          label-loading="Gerando..."
          :params="asyncExportButtonParams"
        />
      </template>
      <template v-if="allTags.length" #tag>
        <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>
    <div class="Drivers__Content">
      <div class="Drivers__Table">
        <DataTable
          :header="readyDrivers.data.headers"
          :table="readyDrivers.data.content"
          is-sticky
        />
        <Pagination @page="search()" @paginate="search()" />
      </div>
    </div>
  </main>
</template>

<script>
import { BaseTreeSelect, BaseDatePicker } from '@/components/atoms'
import { Pagination, DataTable, FiltersTag, AsyncExportButton } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'Drivers',
  components: {
    DataTable,
    BaseTreeSelect,
    TheSubHeader,
    Pagination,
    FiltersTag,
    BaseDatePicker,
    AsyncExportButton
  },
  data() {
    return {
      dateRange: {
        init: null,
        end: null,
      },
      filter: {
        id: [],
        store_id: [],
        transporter_id: [],
        status_id: []
      },
      idDriver: null,
      show: false,
      countOpt: [{ id: 1, name:'Ativo' }, { id: 2, name:'Inativo' }, { id: 3, name:'Pendente' }, { id: 4, name:'Incompleto' }],
      allTags: []
    }
  },

  computed:{
    ...mapState({
      transporters: state => state.transporters.transporters,
      drivers: state => state.drivers.drivers,
      stores: state => state.stores.stores,
      queryString: state => state.queryString.queryString,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
    }),

    ...mapGetters({
      readyDrivers: 'drivers/readyDrivers'
    }),

    DriversShowHide(){
      return this.show
        ? 'Drivers__Dialog--show'
        : 'Drivers__Dialog--hide'
    },

    formattedDateRange: {
      get: function() {
        return [this.dateRange.init, this.dateRange.end]
      },
      set: function(newValue) {
        this.dateRange.init = newValue[0]
        this.dateRange.end = newValue[1]
      },
    },

    asyncExportButtonParams() {
      return {
        date_start: this.dateRange?.init,
        date_end: this.dateRange?.end,
        transporters: this.filter?.transporter_id?.map(t => t.id),
        driver_ids: this.filter?.id?.map(d => d.id),
        store_ids: this.filter?.store_id?.map(s => s.id),
        status_ids: this.filter?.status_id?.map(s => s.id)
      }
    }
  },
  
  mounted() {
    this.fetchTransporters()
    this.fetchStores()
    this.search()
  },

  methods:{
    ...mapActions({
      fetchStores: 'stores/fetchStores',
      fetchTransporters: 'transporters/fetchTransporters',
      fetchDrivers: 'drivers/fetchDrivers',
      setParameters: 'queryString/setParameters',
      setLoading: 'loading/setLoading',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination'
    }),

    updateTags(){
      this.allTags = [...this.filter.id, ...this.filter.store_id, ...this.filter.transporter_id]
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filter.id = this.filter.id.filter((tag) => tag.name !== name)
      this.filter.store_id = this.filter.store_id.filter((tag) => tag.name !== name)
      this.filter.transporter_id = this.filter.transporter_id.filter((tag) => tag.name !== name)
      

      this.resetSearch()
    },

    clearAllTags(){
      this.filter.allTags = []
      this.filter.id = []
      this.filter.transporter_id = []
      this.filter.store_id = []

      this.resetSearch()
    },

    resetSearch() {
      this.clearPagination(this.paginate).then(() => this.search())
    },

    search(){

      this.$nextTick(async () => {

        const params = {
          obj: {
            id: this.filter.id,
            'filter[transporters][transporter_id]': this.filter.transporter_id,
            'filter[stores][store_id]': this.$refs.lojista.checkSelectAll ? [] : this.filter.store_id,
            status_id: this.filter.status_id,
            date_start: this.dateRange?.init,
            date_end: this.dateRange?.end
          },
          id: this.transporterId,
          paginate: this.paginate,
          page: this.page
        }
        
        this.updateTags()
        this.setParameters(this.filter)

        this.fetchDrivers(params).then(() => this.setPagination(this.drivers))

      })
      
    }
  }
}
</script>

<style lang="scss" scoped>
.Drivers {
  &__Content{
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }
  &__Table{
    grid-area: main;
    margin-bottom: 50px;
  }
}
</style>